<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card" style="min-height: 400px">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-md">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Sent to</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Created</th>
                                        <!--                                    <th></th>-->
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="transfers.length > 0">
                                        <tr v-for="(transfer, idx) in transfers" :key="idx">
                                            <td>
                                                <strong>{{ idx+1 }}</strong>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <span class="w-space-no" v-if="receivers[idx] !== undefined">{{ receivers[idx] }}</span>
                                                    <span class="w-space-no" v-else>{{ transfer.data.receiver }}</span>
                                                </div>
                                            </td>
                                            <td>${{transfer.data.amount}}	</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <template>
                                                        <i class="fa fa-circle text-success mr-1"></i>
                                                        COMPLETED
                                                    </template>
                                                </div>
                                            </td>
                                            <td>{{getReadableDate(transfer.data.createdAt)}}</td>
                                            <!--                                        <td>-->
                                            <!--                                            <div class="d-flex">-->
                                            <!--                                                <a href="javascript:void(0)" class="btn btn-primary shadow btn-xs sharp mr-1"><i class="fa fa-pencil"></i></a>-->
                                            <!--                                                <a href="javascript:void(0)" class="btn btn-danger shadow btn-xs sharp"><i class="fa fa-trash"></i></a>-->
                                            <!--                                            </div>-->
                                            <!--                                        </td>-->
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td colspan="5">
                                                <div class="col-12 text-center mt-4">
                                                    <h6 class="text-info">Empty transfer history</h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../../utils/methodsMixin";

export default {
    name: "TransferList",
    data() {
        return {
            fetch_interval: null,
            receivers: []
        }
    },
    mixins: [methodsMixin],
    computed: {
        ...mapGetters('transfer', {
            transfers: 'getTransfers'
        })
    },
    methods: {
      async fetchMetaInfo(){
          if(this.transfers.length > 0){
              clearInterval(this.fetch_interval);
              const promises = this.transfers.map(transfer=>this.$store.dispatch('user/getUser', transfer.data.receiver));
              const results = await Promise.all(promises);
              this.receivers = results.map(result=>result.data.data.email)
          }else{
              if(this.fetch_interval === null){
                  this.fetch_interval = setInterval(this.fetchMetaInfo, 2000)
              }
          }
      }
    },
    mounted() {
        this.$store.dispatch('transfer/fetchTransfers').then(()=>this.fetchMetaInfo())
    }
}
</script>

<style scoped>

</style>